import React, { useState } from 'react';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

axios.defaults.baseURL = "https://api.dotjmstudio.com";
// axios.defaults.withCredentials = true;

function GoogleLoginComponent() {
  const [userInfo, setUserInfo] = useState(null);
  // userDetailInfo 상태 선언
  const [userDetailInfo, setUserDetailInfo] = useState(null);

  const onSuccess = async (credentialResponse) => {
    console.log('Login Success:', credentialResponse);

    try {
      // const tokenInfo = await axios.get(`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${credentialResponse.credential}`);
      // const accessToken = tokenInfo.data.access_token;
      // console.log(tokenInfo)
      // console.log(accessToken)

      const response = await axios.post("/verify_token", {
        token: credentialResponse.credential,
        // accessToken: accessToken
      }, {
        // withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('Server response:', response.data);
      setUserInfo(response.data);

      
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
      }
    }
  };

  const onError = () => {
    console.log('Login Failed');
  };

  const googleLogin = useGoogleLogin({
    scope: "profile email https://www.googleapis.com/auth/profile.agerange.read https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/user.organization.read",
    onSuccess: async tokenResponse => {
      console.log(tokenResponse);
      console.log(tokenResponse.access_token);
      console.log(tokenResponse.credential);
      // fetching userinfo can be done on the client or the server
      // const userInfo = await axios
      //   .get('https://www.googleapis.com/oauth2/v3/userinfo', {
      //     headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
      //   })
      //   .then(res => res.data);

      // console.log(userInfo);

      // const peopleInfo = await axios
      //   .get('https://people.googleapis.com/v1/people/me?personFields=ageRanges,birthdays,genders,organizations', {
      //     headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
      //   })
      //   .then(res => res.data);

      // console.log(peopleInfo);

      const response = await axios.post("/userData", {
        accessToken: tokenResponse.access_token
      }, {
        // withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('Server response:', response.data);
      setUserDetailInfo(response.data);
    },
    // flow: 'implicit', // implicit is the default
  });

  return (
    <div>
      <GoogleLogin
        onSuccess={onSuccess}
        onError={onError}
        scope="profile email https://www.googleapis.com/auth/user.organization.read"
      />
      <button onClick={() => googleLogin()}>구글로 로그인</button>
      {userInfo ? (
          <div>
              <h2>User Info</h2>
              <p>Name: {userInfo.name}</p>
              <p>Email: {userInfo.email}</p>
              <img src={userInfo.picture} alt="profile" />
          </div>
      ) : (
          <div>
              <h2>User Info</h2>
              <p>No user information available.</p>
          </div>
      )}
      {userDetailInfo ? (
          <div>
              <h2>User Detail Info</h2>
              <p>Name: {userDetailInfo.name}</p>
              <p>Email: {userDetailInfo.email}</p>
              <p>organizationDomain: {userDetailInfo.organizationDomain}</p>
              <p>department: {userDetailInfo.department}</p>
              <p>sid: {userDetailInfo.sid}</p>
              {userDetailInfo.peopleInfo.gender && (
                <p>gender: {userDetailInfo.peopleInfo.gender}</p>
              )}
              {userDetailInfo.peopleInfo.ageRange && (
                <p>ageRange: {userDetailInfo.peopleInfo.ageRange}</p>
              )}
              {userDetailInfo.peopleInfo.birthdate && (
                <p>ageRange: {userDetailInfo.peopleInfo.birthdate.year} / {userDetailInfo.peopleInfo.birthdate.month} / {userDetailInfo.peopleInfo.birthdate.day}</p>
              )}
              <img src={userDetailInfo.picture} alt="profile" />
          </div>
      ) : (
          <div>
              <h2>User Detail Info</h2>
              <p>No user information available.</p>
          </div>
      )}
    </div>
  );
}

export default GoogleLoginComponent;
